import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider'; // Importez Divider de Material-UI
import Commandes from '@mui/icons-material/DirectionsRun';
import Planning from '@mui/icons-material/CalendarMonth';
import Candidats from '@mui/icons-material/People';
import Clients from '@mui/icons-material/CorporateFare';
import Sync from '@mui/icons-material/Sync';
import Info from '@mui/icons-material/Info';


function MenuColonne({ isOpen, setIsMenuOpen, setPage }) {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isOpen}
      sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
              marginTop: 6,
        },
      }}
    >
 <List>
        {['Candidats', 'Clients', 'Commandes', 'Plannings'].map((text, index) => (
         <ListItem 
         button 
         key={text} 
         onClick={() => {
           if (index === 0) {
             setPage("Recherche"); // Pour "Candidats"
             setIsMenuOpen(false)
            } else if (index === 1) {
              // setPage pour "Clients", si nécessaire
            } else if (index === 2) {
              //setPage pour "Commandes", si nécessaire
              setPage("Commandes");
              setIsMenuOpen(false)
            } else if (index === 3) {
              // setPage pour "Plannings", si nécessaire
              setPage("Demo");
              setIsMenuOpen(false)
           }
         }}
       >
            <ListItemIcon>
                {index === 0 ? <Candidats /> : null}
                {index === 1 ? <Clients /> : null}
                {index === 2 ? <Commandes /> : null}
                {index === 3 ? <Planning /> : null}
                {/* {index === 4 ? <Info /> : null} */}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <Divider /> {/* Ajoutez le Divider ici */}
        <ListItem button key="Synchro"  onClick={() => {
            setPage("Journal")
            setIsMenuOpen(false)
            }}>
          <ListItemIcon>
            <Sync />
          </ListItemIcon>
          <ListItemText primary="Synchro" />
        </ListItem>
        <Divider /> {/* Ajoutez le Divider ici */}
        <ListItem button key="Infos"  onClick={() => {
            setPage("Infos")
            setIsMenuOpen(false)
            }}>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary="Informations" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MenuColonne;