import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";


function Infos({ loginDetails }) {

return (
<div>
<h1>Infos</h1>

<div>
    <p>
     anael.ovh est une application web simple et rapide pour présenter vos informations ANAEL TT 
    </p>
<p>
    Son installation requiert : 
    <ul>
        <li>- Une base ANAEL TT</li>
        <li>- Un accès à internet</li>
        <li>- Un nom de domaine</li>
    </ul>
</p>

<div className="card">
     Demandez votre exemplaire à<br/>
     Jean-Denis CARRE<br/>
     <b>06.32.15.50.75</b>
    </div>

</div>


{/* <p>Voici les informations de connexion:</p>
<p>Utilisateur: {loginDetails.user}</p>
<p>Mot de passe: {loginDetails.psw}</p> */}
</div>
);


}


export default Infos;