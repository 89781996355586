import React, { useState } from "react";

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Email:", email, "Password:", password);
    // Ici, vous intégreriez la logique de vérification des identifiants
    // Par exemple, appeler une API pour vérifier les identifiants
    onLogin(email, password); // Appeler la fonction onLogin passée en props pour mettre à jour l'état isLoggedIn dans App.js
  };

  return (
    <div className="card loggin" >
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Email:</label>
          <input
            type="text"
            className="card-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>psw</label>
          <input
            className="card-input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
}

export default Login;
