import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Input from "@mui/material/Input";

function Fiche({ cand, suppc }) {
  const [state, setState] = useState({ opt: [] });

  // const [state, setState] = useState({c:cand});
  useEffect(() => {
    // Logique à exécuter lorsque [`cand`](command:_github.copilot.openSymbolFromReferences?%5B%7B%22%24mid%22%3A1%2C%22fsPath%22%3A%22c%3A%5C%5Cdev%5C%5Creact%5C%5Canael%5C%5Csrc%5C%5CComponents%5C%5CRecherche.js%22%2C%22_sep%22%3A1%2C%22external%22%3A%22file%3A%2F%2F%2Fc%253A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22path%22%3A%22%2Fc%3A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22scheme%22%3A%22file%22%7D%2C%7B%22line%22%3A245%2C%22character%22%3A33%7D%5D "src/Components/Recherche.js") change
    console.log("Mise à jour de Fiche avec", cand);
    getPays();
    getSelects();
  }, [cand]); // Cette ligne indique à React de ré-exécuter le useEffect lorsque [`cand`](command:_github.copilot.openSymbolFromReferences?%5B%7B%22%24mid%22%3A1%2C%22fsPath%22%3A%22c%3A%5C%5Cdev%5C%5Creact%5C%5Canael%5C%5Csrc%5C%5CComponents%5C%5CRecherche.js%22%2C%22_sep%22%3A1%2C%22external%22%3A%22file%3A%2F%2F%2Fc%253A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22path%22%3A%22%2Fc%3A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22scheme%22%3A%22file%22%7D%2C%7B%22line%22%3A245%2C%22character%22%3A33%7D%5D "src/Components/Recherche.js") change

  const getSelects = async () => {
    const token = localStorage.getItem("token");
    console.log("token=", token);
    try {
      let url = process.env.REACT_APP_API_URL + "/optionsc";
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur: ${response.status}`);
      }
      const data = await response.json();
      setState((state) => ({ ...state, opt: data }));
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de la requête:",
        error,
        error.message
      );
    }
  };

  const getPays = async () => {
    const token = localStorage.getItem("token");
    console.log("token=", token);
    try {
      let url = process.env.REACT_APP_API_URL + "/par?ind=pay";
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur: ${response.status}`);
      }
      const data = await response.json();
      console.log('pays', data)
      setState((state) => ({ ...state, pays: data }));
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de la requête:",
        error,
        error.message
      );
    }
  };

const  delCandidat = async () => {
  console.log('Suppression du candidat', cand.PERMAT)
  const confirmation = window.confirm("Voulez-vous continuer la suppression de ce candidat ?");
  if (!confirmation) {
    return
  }

  // alert('Suppression du candidat ' +  cand.PERMAT)
  const token = localStorage.getItem("token");
  // console.log("token=", token);
  try {
   // let url = process.env.REACT_APP_API_URL + "/api/delcand?matricule=" + cand.PERMAT;
    let url = process.env.REACT_APP_API_URL + "/del?matricule=" + cand.PERMAT;
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Erreur: ${response.status}`);
    }
    // const data = await response.json();
    // setState((state) => ({ ...state, opt: data }));
  } catch (error) {
    console.error(
      "Erreur lors de l'envoi de la requête:",
      error,
      error.message
    );
  }

  suppc(cand.PERMAT)

}



  return (
    <>
      <div className="none">Fiche candidat

      {cand.PERNOM} &nbsp; {cand.PERPRE} &nbsp; {cand.PERMAT}

      </div>
 
        <div id="identiteCard">
          <div
            className="card"
            // style="width: 32rem;"
          >
            <div className="card-header">
              <h5 className="card-title">Identité</h5>
            </div>
            <div className="card-body">
              <div
              // style="display: flex; justify-content: space-between;"
              >
                <div className="champ none">
                  <label htmlFor="pertip">Civilité</label>
                  <select className="form-control" id="pertip" value={cand.PERTIP}>
                    {state.opt ? (
                      state.opt
                        .filter((e) => e.Ind == "TIT")
                        .map((option) => (
                          <option key={option.Cod} value={option.Lib}>
                            {option.Lib}
                          </option>
                        ))
                    ) : (
                      <option>Chargement...</option>
                    )}
                  </select>
                </div>
                <div className="champ">
                  <label htmlFor="perman">Id</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="perman"
                    aria-describedby="perman"
                    placeholder="Code manuel"
                  />
                </div>
              </div>
              {/* <div className="champ">
                <label htmlFor="pertip">Civilité</label>
                <select className="form-control" id="pertip"></select>
              </div> */}

              <div
              // style="display: flex;justify-content: space-between;"
              >
                <div className="champ">
                  <label htmlFor="pernom">Nom</label>
                  <Input
                    type
                    className="form-control"
                    id="pernom"
                    aria-describedby
                    placeholder="Nom du candidat"
                    value={cand.PERNOM}
                  />
                </div>

                <div className="champ">
                  <label htmlFor="perpre">Prénom</label>
                  <Input
                    type
                    className="form-control"
                    id="perpre"
                    aria-describedby
                    placeholder="Prénom"
                    value = {cand.PERPRE}
                  />
                </div>
              </div>

              <div className="champ">
                <label htmlFor="perru1">Adresse</label>
                <Input
                  type="text"
                  className="form-control"
                  id="perru1"
                  aria-describedby
                  placeholder="Adresse"
                  value={cand.PERRU1}
                />
              </div>
              <div className="champ">
              <label htmlFor="perru2"></label>
                {/* <!-- <label for="perru2">Complément d'adresse</label> width: 470px;--> */}
                {cand.PERRU2 && (
                  <Input
                    type="text"
                    className="form-control"
                    id="perru2"
                    aria-describedby
                    placeholder="Complément d'adresse"
                    value={cand.PERRU2}
                  />
                )}
               
              </div>

              <div className="champ">

                  {/* <label htmlFor="percpo"></label> */}
                 
              <div className="champ">
                  <Input
                    type
                    className="form-control, cp"
                    id="percpo"
                    aria-describedby
                    placeholder="CP"
                    value={cand.PERCPO}
                    //   style="width: 80px;"
                    />
               
                  <Input
                    type
                    className="form-control"
                    id="pervil"
                    aria-describedby
                    placeholder="Ville"
                    value={cand.PERVIL}
                    //   style="width: 250px;"
                    />
              </div>
                  
       

<div className="none">

                <div className="champ">
                  <label htmlFor="percc1">Pays</label>
                  <select className="form-control" id="percpa" value={cand.PERPA2}>
                    {/* <!-- Le pays de correspondance --> */}
                    {state.pays ? (
                      state.pays
                        //.filter((e) => e.Ind == "TIT")
                        .map((option) => (
                          <option key={option.Cod} value={option.Lib.substring(15)}>
                            {option.Lib}
                          </option>
                        ))
                    ) : (
                      <option>Chargement...</option>
                    )}
                  </select>
                </div>
              {/* </div> */}
            {/* </div> */}

            {/* <!-- Liste des 3 téléphones et de leur libellé --> */}
            <div
            // style="display: flex;flex-direction: column;flex-wrap:wrap;padding-left: 10px;"
            >
              <div
              //   style="display: flex; display: flex;justify-content: space-between;"
              >
                <div
                //   style="margin-right: 10px;"
                >
                  <label htmlFor="pertlt1">Commentaires tel</label>
                  <input
                    type
                    className="form-control"
                    id="pertlt1"
                    aria-describedby
                    placeholder="Lib tel"
                  />
                </div>
                <div
                //   style="margin-right: 10px;"
                >
                  <label htmlFor="pertlp">Téléphones</label>
                  <input
                    type
                    className="form-control"
                    id="pertlp"
                    aria-describedby
                    placeholder="Téléphone"
                  />
                </div>
              </div>

              <div
              //   style="display: flex;display: flex;justify-content: space-between;"
              >
                <div
                //   style="margin-right: 10px;margin-top: 5px;"
                >
                  <input
                    type
                    className="form-control"
                    id="perplt2"
                    aria-describedby
                    placeholder="Lib tel"
                  />
                </div>
                <div
                //   style="margin-right: 10px;margin-top: 5px;"
                >
                  <input
                    type
                    className="form-control"
                    id="pertl2"
                    aria-describedby
                    placeholder="Téléphone"
                  />
                </div>
              </div>

              <div
              //   style="display: flex;display: flex;justify-content: space-between;"
              >
                <div
                //   style="margin-right: 10px;margin-top: 5px;"
                >
                  <input
                    type
                    className="form-control"
                    id="perlt3"
                    aria-describedby
                    placeholder="Lib tel"
                  />
                </div>
                <div
                //   style="margin-right: 10px;margin-top: 5px;"
                >
                  <input
                    type
                    className="form-control"
                    id="perttl3"
                    aria-describedby
                    placeholder="Téléphone"
                  />
                </div>
              </div>
            </div>
            {/* <!-- Fin de la colonne des tel  --> */}

            <div
            //   style="margin: 10px;"
            >
              <label htmlFor="permai">Email</label>
              <input
                type
                className="form-control"
                id="permai"
                aria-describedby
                placeholder="Email"
              />
            </div>
          </div>
        </div>
</div>
  
          <div
            className="card none"
            // style="width: 32rem; margin-left: 20px;"
            id="naissanceCard"
          >
            <div className="card-header">
              <h5 className="card-title">Naissance</h5>
            </div>
            <div className="card-body">
              {/* <!-- <div style="display: flex;"> --> */}
              {/* <!-- La nationalité --> */}
              <div>
                <label htmlFor="pernat">Nationalité</label>
                <select className="form-control" id="pernat">
                  {/* <!-- Les nationalités --> */}
                </select>
              </div>

              <div>
                <label htmlFor="perdtn">Date de naissance</label>
                <input
                  type="date"
                  className="form-control"
                  id="perdtn"
                  aria-describedby
                  placeholder="Date de naissance"
                />
              </div>
              <div
              // style="display: flex;"
              >
                <div>
                  <label htmlFor="perlie">Lieu de naissance</label>
                  <input
                    type
                    className="form-control"
                    id="perlie"
                    aria-describedby
                    placeholder="Lieu de naissance"
                    //   style="width: 300px;"
                  />
                </div>
                <div>
                  <label htmlFor="perdep">Département</label>
                  <input
                    type
                    className="form-control"
                    id="perdep"
                    aria-describedby
                    placeholder="Lieu de naissance"
                    //   style="width: 80px;"
                  />
                </div>
              </div>
              {/* <!-- Pays de naissance  --> */}
              <div>
                <label htmlFor="perpay">Pays de naissance</label>
                <select className="form-control" id="perpay">
                  {/* <!-- Les pays --> */}
                </select>
              </div>

              <div
              // style="display: flex;"
              >
                <div>
                  <label htmlFor="persit">Situation familiale</label>
                  <select className="form-control" id="persit">
                    {/* <!-- Les situations familliales --> */}
                  </select>
                </div>

                <div>
                  <label htmlFor="perpac">Enfants à charge</label>
                  <input
                    type
                    className="form-control"
                    id="perpac"
                    aria-describedby
                    //   style="width: 50px;margin-left: 10px;"
                    placeholder="Nb enfants à charge"
                  />
                </div>
              </div>

              <div
              // style="display: flex;"
              >
                <div>
                  <label htmlFor="pernss">Nir</label>
                  <input
                    type
                    className="form-control"
                    id="pernss"
                    //   style="width: 170px;"
                    aria-describedby
                    placeholder="Numéro de sécurité sociale"
                  />
                </div>

                <div>
                  <label htmlFor="pernsc">Clef Nir</label>
                  <input
                    type
                    className="form-control"
                    id="pernsc"
                    //   style="width: 50px;margin-left: 10px;"
                    aria-describedby
                    placeholder="Commentaires"
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
          <div
            className="card"
          >
            <div className="card-header">
              <h5 className="card-title">Actions spéciales</h5>
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={delCandidat}
            >
              Supprimer ce candidat
            </button>
          </div>
      </div>
    </>
  );
}

export default Fiche;
