import React, { useState, useEffect } from "react";

export default function Commandes() {
  return (
    <div>
        <h1> Planning des commandes</h1>
      <div className="cl3">
        <select disabled>
          <option value="099">AGENCE MODELE</option>
          <option value="101">VAC ACTION PARIS</option>
          <option value="102">VAC ACTION VERSAILLES</option>
          <option value="103">VAC ACTION MELUN</option>
          <option value="110">Agence Paris AMI 110</option>
          <option value="112">Agence Paris 112</option>
          <option value="114">Expected -Bureau Up Skills 114</option>
          <option value="115">Agence Paris 115</option>
          <option value="116">Agence Paris 116</option>
          <option value="117">Agence Paris 117</option>
          <option value="127">Agence 127</option>
          <option value="131">Agence Bezons 131</option>
          <option value="132">Agence Bezons 132</option>
          <option value="134">Agence Evry 134</option>
          <option value="135">Agence Evry 135</option>
          <option value="136">Agence Evry 136</option>
          <option value="138">Agence 138</option>
          <option value="139">Agence 139</option>
          <option value="140">Agence Paris 140</option>
          <option value="141">Agence Paris 141</option>
          <option value="142">Agence Cergy 142</option>
          <option value="144">Expected -Bureau Up Skills 144</option>
          <option value="146">Agence gennevilliers 146</option>
          <option value="147">Agence Paris 147</option>
          <option value="148">Agence Paris 148</option>
          <option value="159">Agence Gennevilliers 159</option>
          <option value="160">Agence 160</option>
          <option value="161">Agence 161</option>
          <option value="162">Agence 162</option>
          <option value="180">Agence GD INTERIM 180</option>
          <option value="181">Agence GD PRESTIGE 181</option>
          <option value="182">Agence GD TOURNAN 182</option>
          <option value="183">Agence GD Tournan 183</option>
          <option value="203">Agence Grands Compte 203</option>
          <option value="204">Agence PME Paris Est 204</option>
          <option value="207">Agence Conseil - Serv. 207</option>
          <option value="210">Expected- Bureau Up Skills 210</option>
          <option value="213">Agence 213</option>
          <option value="214">Expected- Bureau Up Skills 214</option>
          <option value="216">Agence PME Paris Ouest 216</option>
          <option value="220">Agence Paris Bankable 220</option>
          <option value="221">Agence Paris 221</option>
          <option value="222">Expected -Bureau Up Skills 222</option>
          <option value="223">Expected -Bureau Up Skills 223</option>
          <option value="242">Expected bureau Upskills 242</option>
          <option value="248">Expected -Bureau Up Skills 248</option>
          <option value="250">Agence Paris Gest Compt 250</option>
          <option value="251">Agence Paris 251</option>
          <option value="252">Expected -Bureau Up Skills 252</option>
          <option value="254">Expected -Bureau Up Skills 254</option>
          <option value="255">Expected -Bureau Up Skills 255</option>
          <option value="256">Agence Paris Ste Anne 256</option>
          <option value="257">Agence Paris Rome 257</option>
          <option value="258">Agence PARIS 258</option>
          <option value="259">ERGALIS 259</option>
          <option value="260">Expected -Bureau Up Skills 260</option>
          <option value="261">Agence Poissy 261</option>
          <option value="262">Agence Montigny 262</option>
          <option value="263">Agence 263</option>
          <option value="271">Agence Poissy 271</option>
          <option value="290">Expected -Bureau Up Skills 290</option>
          <option value="291">Expected -Bureau Up Skills 291</option>
          <option value="301">Agence Rennes AMI MEDICAL 301</option>
          <option value="314">Expected -Bureau Up Skills 314</option>
          <option value="333">Agence AMI MEDICAL 333</option>
          <option value="350">Agence Normandie 350</option>
          <option value="361">Agence AWEL Rennes 361</option>
          <option value="362">Agence AWEL Brest 362</option>
          <option value="363">Agence AWEL Guer 363</option>
          <option value="364">Agence AWEL St Brieuc 364</option>
          <option value="365">Agence AWEL Lorient 365</option>
          <option value="367">Agence AWEL Lannion 367</option>
          <option value="368">Agence AWEL St Malo 368</option>
          <option value="369">Agence AWEL Morlaix 369</option>
          <option value="370">Agence AWEL Quimper 370</option>
          <option value="371">Agence AWEL Chantepie 371</option>
          <option value="372">Agence AWEL St James 372</option>
          <option value="376">Agence AWEL Lanvollon 376</option>
          <option value="377">Agence AWEL 377</option>
          <option value="378">Agence AWEL 378</option>
          <option value="401">Agence Lyon 401</option>
          <option value="403">Agence Chamb�ry Tech Indus 403</option>
          <option value="405">Agence Chamb�ry Tertiaire 405</option>
          <option value="406">Agence Chamb�ry 406 tech Indus</option>
          <option value="407">Agence Echirolles 407</option>
          <option value="408">Agence St Priest 408</option>
          <option value="410">Agence Loire GD 410</option>
          <option value="419">GD INTERIM Rhone Alpes 419</option>
          <option value="420">Agence St Etienne Tert. 420</option>
          <option value="421">Agence St Etienne Recrut 421</option>
          <option value="422">Agence Annecy 422</option>
          <option value="423">Agence Lyon GD 423</option>
          <option value="424">Agence Lyon GD Prestige 424</option>
          <option value="425">Expected -Bureau Up Skills425</option>
          <option value="426">Expected -Bureau Up Skills426</option>
          <option value="427">Agence Lyon 427</option>
          <option value="430">Agence Colombe 430</option>
          <option value="432">Agence Annecy 432</option>
          <option value="438">Agence St Poucain 438</option>
          <option value="440">Agence Moulins 440</option>
          <option value="443">Agence Issoire 443</option>
          <option value="444">Agence Roanne 444</option>
          <option value="445">Agence Roanne M�dical 445</option>
          <option value="446">Agence Roanne 446</option>
          <option value="448">Agence St Chamond 448</option>
          <option value="449">Agence St Chamond 449</option>
          <option value="450">Agence Roanne 450</option>
          <option value="451">Agence Pouilly 451</option>
          <option value="452">Agence Feurs 452</option>
          <option value="453">Agence St Etienne 453</option>
          <option value="454">Agence Pontcharra 454</option>
          <option value="455">Agence St bonnet le chateau455</option>
          <option value="456">Agence St bonnet le chateau456</option>
          <option value="457">Agence St Etienne 457</option>
          <option value="458">Agence Chazelles sur lyon 458</option>
          <option value="459">Agence Feurs 459</option>
          <option value="460">Agence St Rambert s Albon 460</option>
          <option value="461">Agence Clermont Ferrand 461</option>
          <option value="462">Agence St Symphorien 462</option>
          <option value="463">Agence Pont du Ch�teau 463</option>
          <option value="464">Agence St Just 464</option>
          <option value="466">Agence Brignais BTP 466</option>
          <option value="467">Agence Montbrison 467</option>
          <option value="468">Agence Monistrol 468</option>
          <option value="469">Agence St Laurent 469</option>
          <option value="470">Agence St Rambert s Albon 470</option>
          <option value="471">Agence Heyrieux 471</option>
          <option value="472">Agence Heyrieux 472</option>
          <option value="473">Agence Heyrieux 473</option>
          <option value="474">Agence 474</option>
          <option value="476">Agence St Laurent 476</option>
          <option value="477">Agence Le Puy 477</option>
          <option value="478">Agence Chassieu 478</option>
          <option value="479">Agence Villefranche 479</option>
          <option value="480">Agence miribel 480</option>
          <option value="482">Agence Echirolles 482</option>
          <option value="483">Agence Echirolles Medical 483</option>
          <option value="485">Agence Amberieu 485</option>
          <option value="486">Agence Brignais Tech Indus 486</option>
          <option value="488">Agence AMITT Echirolles 488</option>
          <option value="490">Expected -Bureau Up Skills 490</option>
          <option value="491">Agence ELITT Echirolles 491</option>
          <option value="492">Agence Echirolles 492</option>
          <option value="493">Agence Echirolles 493</option>
          <option value="494">Agence Echirolles 494</option>
          <option value="495">Agence Echirolles 495</option>
          <option value="496">LAURENT PROTIERE</option>
          <option value="501">Agence Seltz 501</option>
          <option value="503">Agence Seltz 503</option>
          <option value="504">Expected -Bureau Up Skills 504</option>
          <option value="505">Agence Strasbourg 505</option>
          <option value="506">Agence Strasbourg 506</option>
          <option value="507">Agence Strasbourg 507</option>
          <option value="509">Agence Haguenau 509</option>
          <option value="511">Agence Haguenau 511</option>
          <option value="512">Agence GD 512</option>
          <option value="513">Agence GD Nancy 513</option>
          <option value="520">Agence Nancy 520</option>
          <option value="525">Agence Strasbourg GD 525</option>
          <option value="601">Expected -Bureau Up Skills 601</option>
          <option value="602">Agence Lille Banque Assur 602</option>
          <option value="652">Agence Lille 652</option>
          <option value="653">Agence Lille 653</option>
          <option value="654">Agence Lille 654</option>
          <option value="655">Agence Lille 655</option>
          <option value="656">Expected -Bureau Up Skills 656</option>
          <option value="657">Agence Lille GD 657</option>
          <option value="670">Agence Arras 670</option>
          <option value="671">Agence Arras Tech Indus 671</option>
          <option value="672">Agence Arras GD 672</option>
          <option value="680">Agence Valenciennes 680</option>
          <option value="681">Agence Valenciennes 681</option>
          <option value="702">Expected -Bureau Up Skills 702</option>
          <option value="703">Agence GD Vannes 703</option>
          <option value="704">Agence Proxi.Vannes 704</option>
          <option value="707">Expected -Bureau Up Skills 707</option>
          <option value="708">Agence Nantes M�dical 708</option>
          <option value="722">Agence GD Lab�ge 722</option>
          <option value="729">Agence Tech Indus Lab�ge 729</option>
          <option value="730">Agence B�timent Lab�ge 730</option>
          <option value="731">Agence Toulouse 731</option>
          <option value="732">Expected -Bureau Up Skills 732</option>
          <option value="733">Expected -Bureau Up Skills 733</option>
          <option value="734">Agence Bordeaux 734</option>
          <option value="735">Agence Bordeaux 735</option>
          <option value="736">Agence Bordeaux 736</option>
          <option value="737">Agence Bordeaux 737</option>
          <option value="738">Agence banque Assurance 738</option>
          <option value="739">Expected -Bureau Up Skills 739</option>
          <option value="791">Expected -Bureau Up Skills 791</option>
          <option value="802">Agence GD Aix 802</option>
          <option value="803">Agence Aix Banque 803</option>
          <option value="804">Agence Aix Batiment 804</option>
          <option value="805">Expected -Bureau Up Skills 805</option>
          <option value="806">Agence Aix M�dical 806</option>
          <option value="807">Expected -Bureau Up Skills 807</option>
          <option value="815">Agence Montelimar 815</option>
          <option value="817">Agence Valence 817</option>
          <option value="818">Agence Valence 818</option>
          <option value="820">Agence Nice GD 820</option>
          <option value="821">Agence Marseille GD 821</option>
          <option value="824">Agence ELITT Pierrelatte 824</option>
          <option value="825">Agence ELITT Pierrelatte 825</option>
          <option value="840">Agence Aix 840</option>
          <option value="841">Agence ELITT Aix 841</option>
          <option value="910">T.P. SALES &amp; MARKETING 910</option>
          <option value="915">TP TRAVEL RETAIL 915</option>
          <option value="919">TALENTPEOPLE LUXE &amp; BEAUTE 919</option>
          <option value="921">TALENT PEOPLE (LGWINID)</option>
          <option value="922">TALENT PEOPLE</option>
          <option value="930">SELEXENS</option>
          <option value="951">EV SOLUTIONS</option>
          <option value="991">UPSKILLS SEARCH</option>
          <option value="993">UPSKILLS</option>
        </select>

        <select disabled>
          <option value="01">Janvier</option>
          <option value="02">Février</option>
          <option value="03">Mars</option>
          <option value="04">Avril</option>
          <option value="05">Mai</option>
          <option value="06">Juin</option>
          <option value="07">Juillet</option>
          <option value="08">Août</option>
          <option value="09">Septembre</option>
          <option value="10">Octobre</option>
          <option value="11">Novembre</option>
          <option value="12">Décembre</option>
        </select >
        <select value="2024" disabled>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>

        {/* <div className="cl0"> */}

        
        <table className="cl6">
          <thead>
            <tr>
              <th className="cl7">
                Client<div></div>
              </th>
              <th className="cl4">l1</th>
              <th className="cl4">m2</th>
              <th className="cl4">m3</th>
              <th className="cl4">j4</th>
              <th className="cl4">v5</th>
              <th className="cl5">s6</th>
              <th className="cl5">d7</th>
              <th className="cl4">l8</th>
              <th className="cl4">m9</th>
              <th className="cl4">m10</th>
              <th className="cl4">j11</th>
              <th className="cl4">v12</th>
              <th className="cl5">s13</th>
              <th className="cl5">d14</th>
              <th className="cl4">l15</th>
              <th className="cl4">m16</th>
              <th className="cl4">m17</th>
              <th className="cl4">j18</th>
              <th className="cl4">v19</th>
              <th className="cl5">s20</th>
              <th className="cl5">d21</th>
              <th className="cl4">l22</th>
              <th className="cl4">m23</th>
              <th className="cl4">m24</th>
              <th className="cl4">j25</th>
              <th className="cl4">v26</th>
              <th className="cl5">s27</th>
              <th className="cl5">d28</th>
              <th className="cl4">l29</th>
              <th className="cl4">m30</th>
              <th className="cl4">m31</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cl8">RESIDENCE PONT BERTIN</td>
              <td id="6550000071" className="cl1"></td>
              <td id="6550000072" className="cl1"></td>
              <td id="6550000073" className="cl1"></td>
              <td id="6550000074" className="cl1"></td>
              <td id="6550000075" className="cl1"></td>
              <td id="6550000076" className="cl1"></td>
              <td id="6550000077" className="cl1"></td>
              <td id="6550000078" className="cl1"></td>
              <td id="6550000079" className="cl1"></td>
              <td id="65500000710" className="cl1"></td>
              <td id="65500000711" className="cl1"></td>
              <td id="65500000712" className="cl1"></td>
              <td id="65500000713" className="cl1"></td>
              <td id="65500000714" className="cl1"></td>
              <td id="65500000715" className="cl1"></td>
              <td id="65500000716" className="cl1"></td>
              <td id="65500000717" className="cl1"></td>
              <td id="65500000718" className="cl1"></td>
              <td id="65500000719" className="cl1"></td>
              <td id="65500000720" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500000721" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500000722" className="cl1"></td>
              <td id="65500000723" className="cl1"></td>
              <td id="65500000724" className="cl1"></td>
              <td id="65500000725" className="cl1"></td>
              <td id="65500000726" className="cl1"></td>
              <td id="65500000727" className="cl1"></td>
              <td id="65500000728" className="cl1"></td>
              <td id="65500000729" className="cl1"></td>
              <td id="65500000730" className="cl1"></td>
              <td id="65500000731" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">CENTRE L'ESPOIR</td>
              <td id="6550009131" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009132" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009133" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009134" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550009135" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009136" className="cl1">
                <p className="cl9">7</p>
              </td>
              <td id="6550009137" className="cl1">
                <p className="cl9">4</p>
              </td>
              <td id="6550009138" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009139" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091310" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091311" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091312" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091313" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091314" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091315" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091316" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500091317" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091318" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091319" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091320" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091321" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091322" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091323" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091324" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091325" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091326" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091327" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091328" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091329" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091330" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500091331" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">IME LE MESNIL DE LA BEUVRECQUE</td>
              <td id="6550009151" className="cl1"></td>
              <td id="6550009152" className="cl1"></td>
              <td id="6550009153" className="cl1"></td>
              <td id="6550009154" className="cl1"></td>
              <td id="6550009155" className="cl1">
                <p className="cl2">18</p>
              </td>
              <td id="6550009156" className="cl1"></td>
              <td id="6550009157" className="cl1">
                <p className="cl2">23</p>
              </td>
              <td id="6550009158" className="cl1"></td>
              <td id="6550009159" className="cl1"></td>
              <td id="65500091510" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091511" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500091512" className="cl1"></td>
              <td id="65500091513" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091514" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500091515" className="cl1"></td>
              <td id="65500091516" className="cl1"></td>
              <td id="65500091517" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500091518" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500091519" className="cl1"></td>
              <td id="65500091520" className="cl1"></td>
              <td id="65500091521" className="cl1"></td>
              <td id="65500091522" className="cl1"></td>
              <td id="65500091523" className="cl1"></td>
              <td id="65500091524" className="cl1"></td>
              <td id="65500091525" className="cl1"></td>
              <td id="65500091526" className="cl1"></td>
              <td id="65500091527" className="cl1"></td>
              <td id="65500091528" className="cl1"></td>
              <td id="65500091529" className="cl1"></td>
              <td id="65500091530" className="cl1"></td>
              <td id="65500091531" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">CCAS DE LILLE</td>
              <td id="6550000281" className="cl1"></td>
              <td id="6550000282" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000283" className="cl1"></td>
              <td id="6550000284" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000285" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550000286" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550000287" className="cl1"></td>
              <td id="6550000288" className="cl1"></td>
              <td id="6550000289" className="cl1"></td>
              <td id="65500002810" className="cl1"></td>
              <td id="65500002811" className="cl1"></td>
              <td id="65500002812" className="cl1"></td>
              <td id="65500002813" className="cl1"></td>
              <td id="65500002814" className="cl1"></td>
              <td id="65500002815" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500002816" className="cl1"></td>
              <td id="65500002817" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500002818" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500002819" className="cl1"></td>
              <td id="65500002820" className="cl1"></td>
              <td id="65500002821" className="cl1"></td>
              <td id="65500002822" className="cl1"></td>
              <td id="65500002823" className="cl1"></td>
              <td id="65500002824" className="cl1"></td>
              <td id="65500002825" className="cl1"></td>
              <td id="65500002826" className="cl1"></td>
              <td id="65500002827" className="cl1"></td>
              <td id="65500002828" className="cl1"></td>
              <td id="65500002829" className="cl1"></td>
              <td id="65500002830" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="65500002831" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">MAIS DE RET L'ARCHE</td>
              <td id="6550000391" className="cl1">
                <p className="cl2">28</p>
              </td>
              <td id="6550000392" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000393" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000394" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000395" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000396" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550000397" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000398" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000399" className="cl1">
                <p className="cl2">20</p>
              </td>
              <td id="65500003910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003911" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003913" className="cl1">
                <p className="cl2">16</p>
              </td>
              <td id="65500003914" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500003915" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003916" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500003917" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500003918" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003919" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003920" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003921" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500003922" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500003923" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003924" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003927" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003928" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003929" className="cl1">
                <p className="cl2">-20</p>
              </td>
              <td id="65500003930" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500003931" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">M.A.S DE BONDUES</td>
              <td id="6550000411" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550000412" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000413" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000414" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000415" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550000416" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000417" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000418" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000419" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004110" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500004111" className="cl1">
                <p className="cl2">9</p>
              </td>
              <td id="65500004112" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004113" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500004114" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004115" className="cl1">
                <p className="cl2">8</p>
              </td>
              <td id="65500004116" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004117" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004118" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004119" className="cl1"></td>
              <td id="65500004120" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500004121" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500004122" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004123" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500004124" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004125" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500004126" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500004127" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004128" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004129" className="cl1">
                <p className="cl2">-27</p>
              </td>
              <td id="65500004130" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500004131" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">RESIDENCE GILBERT FORESTIER</td>
              <td id="6550000591" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000592" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550000593" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000594" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000595" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000596" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000597" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550000598" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550000599" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500005910" className="cl1"></td>
              <td id="65500005911" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005913" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005914" className="cl1"></td>
              <td id="65500005915" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500005916" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005917" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005918" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500005919" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005920" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005921" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005922" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005923" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500005924" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500005925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005926" className="cl1"></td>
              <td id="65500005927" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500005928" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005929" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005930" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500005931" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">RES LES PROVINCES DU NORD</td>
              <td id="6550009291" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009292" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550009293" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009294" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550009295" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009296" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009297" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009298" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550009299" className="cl1">
                <p className="cl2">11</p>
              </td>
              <td id="65500092910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092911" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500092912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092913" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092914" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092915" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092916" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092917" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092918" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500092919" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500092920" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092921" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092922" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092923" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092924" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092927" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500092928" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092929" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092930" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500092931" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CENTRE DE SANTE DE LILLE FIVES</td>
              <td id="6550002781" className="cl1"></td>
              <td id="6550002782" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550002783" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550002784" className="cl1"></td>
              <td id="6550002785" className="cl1"></td>
              <td id="6550002786" className="cl1"></td>
              <td id="6550002787" className="cl1"></td>
              <td id="6550002788" className="cl1"></td>
              <td id="6550002789" className="cl1"></td>
              <td id="65500027810" className="cl1"></td>
              <td id="65500027811" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500027812" className="cl1"></td>
              <td id="65500027813" className="cl1"></td>
              <td id="65500027814" className="cl1"></td>
              <td id="65500027815" className="cl1"></td>
              <td id="65500027816" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500027817" className="cl1"></td>
              <td id="65500027818" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500027819" className="cl1"></td>
              <td id="65500027820" className="cl1"></td>
              <td id="65500027821" className="cl1"></td>
              <td id="65500027822" className="cl1"></td>
              <td id="65500027823" className="cl1"></td>
              <td id="65500027824" className="cl1"></td>
              <td id="65500027825" className="cl1"></td>
              <td id="65500027826" className="cl1"></td>
              <td id="65500027827" className="cl1"></td>
              <td id="65500027828" className="cl1"></td>
              <td id="65500027829" className="cl1"></td>
              <td id="65500027830" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500027831" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">IME LA CIGOGNE</td>
              <td id="6550009631" className="cl1"></td>
              <td id="6550009632" className="cl1"></td>
              <td id="6550009633" className="cl1"></td>
              <td id="6550009634" className="cl1"></td>
              <td id="6550009635" className="cl1"></td>
              <td id="6550009636" className="cl1"></td>
              <td id="6550009637" className="cl1"></td>
              <td id="6550009638" className="cl1"></td>
              <td id="6550009639" className="cl1"></td>
              <td id="65500096310" className="cl1"></td>
              <td id="65500096311" className="cl1"></td>
              <td id="65500096312" className="cl1"></td>
              <td id="65500096313" className="cl1"></td>
              <td id="65500096314" className="cl1"></td>
              <td id="65500096315" className="cl1"></td>
              <td id="65500096316" className="cl1"></td>
              <td id="65500096317" className="cl1"></td>
              <td id="65500096318" className="cl1"></td>
              <td id="65500096319" className="cl1"></td>
              <td id="65500096320" className="cl1"></td>
              <td id="65500096321" className="cl1"></td>
              <td id="65500096322" className="cl1"></td>
              <td id="65500096323" className="cl1"></td>
              <td id="65500096324" className="cl1"></td>
              <td id="65500096325" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500096326" className="cl1"></td>
              <td id="65500096327" className="cl1"></td>
              <td id="65500096328" className="cl1"></td>
              <td id="65500096329" className="cl1"></td>
              <td id="65500096330" className="cl1"></td>
              <td id="65500096331" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">ASSAD DE LILLE</td>
              <td id="6550006801" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550006802" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550006803" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550006804" className="cl1"></td>
              <td id="6550006805" className="cl1"></td>
              <td id="6550006806" className="cl1"></td>
              <td id="6550006807" className="cl1"></td>
              <td id="6550006808" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550006809" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500068010" className="cl1">
                <p className="cl2">10</p>
              </td>
              <td id="65500068011" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068012" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500068013" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500068014" className="cl1"></td>
              <td id="65500068015" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068016" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068017" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068018" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500068019" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068020" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068021" className="cl1"></td>
              <td id="65500068022" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068023" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500068024" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500068025" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068026" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500068027" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068028" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068029" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500068030" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500068031" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">FONDATION HENRY DELERUE</td>
              <td id="6550009091" className="cl1">
                <p className="cl2">31</p>
              </td>
              <td id="6550009092" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550009093" className="cl1"></td>
              <td id="6550009094" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009095" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009096" className="cl1"></td>
              <td id="6550009097" className="cl1"></td>
              <td id="6550009098" className="cl1"></td>
              <td id="6550009099" className="cl1"></td>
              <td id="65500090910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090911" className="cl1">
                <p className="cl2">19</p>
              </td>
              <td id="65500090912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090913" className="cl1">
                <p className="cl2">9</p>
              </td>
              <td id="65500090914" className="cl1"></td>
              <td id="65500090915" className="cl1"></td>
              <td id="65500090916" className="cl1"></td>
              <td id="65500090917" className="cl1">
                <p className="cl2">8</p>
              </td>
              <td id="65500090918" className="cl1"></td>
              <td id="65500090919" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500090920" className="cl1">
                <p className="cl2">10</p>
              </td>
              <td id="65500090921" className="cl1"></td>
              <td id="65500090922" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090923" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500090924" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500090925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090926" className="cl1"></td>
              <td id="65500090927" className="cl1"></td>
              <td id="65500090928" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090929" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500090930" className="cl1"></td>
              <td id="65500090931" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">FAM DE LINSELLES</td>
              <td id="6550006041" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550006042" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550006043" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550006044" className="cl1"></td>
              <td id="6550006045" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550006046" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="6550006047" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550006048" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550006049" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060410" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060411" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500060412" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060413" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060414" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500060415" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060416" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060417" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500060418" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060419" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500060420" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500060421" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060422" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060423" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500060424" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060425" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500060426" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500060427" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060428" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500060429" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500060430" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500060431" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">AFEJI IME JEAN LOMBARD</td>
              <td id="6550018111" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="6550018112" className="cl1"></td>
              <td id="6550018113" className="cl1"></td>
              <td id="6550018114" className="cl1"></td>
              <td id="6550018115" className="cl1"></td>
              <td id="6550018116" className="cl1"></td>
              <td id="6550018117" className="cl1">
                <p className="cl2">-5</p>
              </td>
              <td id="6550018118" className="cl1">
                <p className="cl2">14</p>
              </td>
              <td id="6550018119" className="cl1"></td>
              <td id="65500181110" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500181111" className="cl1"></td>
              <td id="65500181112" className="cl1"></td>
              <td id="65500181113" className="cl1"></td>
              <td id="65500181114" className="cl1"></td>
              <td id="65500181115" className="cl1">
                <p className="cl2">-4</p>
              </td>
              <td id="65500181116" className="cl1">
                <p className="cl2">14</p>
              </td>
              <td id="65500181117" className="cl1"></td>
              <td id="65500181118" className="cl1">
                <p className="cl2">-16</p>
              </td>
              <td id="65500181119" className="cl1"></td>
              <td id="65500181120" className="cl1"></td>
              <td id="65500181121" className="cl1"></td>
              <td id="65500181122" className="cl1"></td>
              <td id="65500181123" className="cl1"></td>
              <td id="65500181124" className="cl1"></td>
              <td id="65500181125" className="cl1"></td>
              <td id="65500181126" className="cl1"></td>
              <td id="65500181127" className="cl1"></td>
              <td id="65500181128" className="cl1"></td>
              <td id="65500181129" className="cl1"></td>
              <td id="65500181130" className="cl1">
                <p className="cl2">-8</p>
              </td>
              <td id="65500181131" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">RESIDENCE LES ROSES</td>
              <td id="6550019291" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019292" className="cl1">
                <p className="cl2">0</p>
              </td>
              <td id="6550019293" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550019294" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019295" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019296" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019297" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019298" className="cl1">
                <p className="cl2">12</p>
              </td>
              <td id="6550019299" className="cl1">
                <p className="cl2">22</p>
              </td>
              <td id="65500192910" className="cl1"></td>
              <td id="65500192911" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500192912" className="cl1"></td>
              <td id="65500192913" className="cl1"></td>
              <td id="65500192914" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500192915" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192916" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500192917" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192918" className="cl1"></td>
              <td id="65500192919" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192920" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192921" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500192922" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500192923" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192924" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192926" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500192927" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500192928" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500192929" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500192930" className="cl1"></td>
              <td id="65500192931" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">FOYER DE VIE LES BAZENNES</td>
              <td id="6550019311" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019312" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550019313" className="cl1"></td>
              <td id="6550019314" className="cl1"></td>
              <td id="6550019315" className="cl1"></td>
              <td id="6550019316" className="cl1"></td>
              <td id="6550019317" className="cl1"></td>
              <td id="6550019318" className="cl1"></td>
              <td id="6550019319" className="cl1"></td>
              <td id="65500193110" className="cl1"></td>
              <td id="65500193111" className="cl1"></td>
              <td id="65500193112" className="cl1"></td>
              <td id="65500193113" className="cl1"></td>
              <td id="65500193114" className="cl1"></td>
              <td id="65500193115" className="cl1"></td>
              <td id="65500193116" className="cl1"></td>
              <td id="65500193117" className="cl1"></td>
              <td id="65500193118" className="cl1"></td>
              <td id="65500193119" className="cl1"></td>
              <td id="65500193120" className="cl1"></td>
              <td id="65500193121" className="cl1"></td>
              <td id="65500193122" className="cl1"></td>
              <td id="65500193123" className="cl1"></td>
              <td id="65500193124" className="cl1"></td>
              <td id="65500193125" className="cl1"></td>
              <td id="65500193126" className="cl1"></td>
              <td id="65500193127" className="cl1"></td>
              <td id="65500193128" className="cl1"></td>
              <td id="65500193129" className="cl1"></td>
              <td id="65500193130" className="cl1"></td>
              <td id="65500193131" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">CLINIQUE DE LA MITTERIE</td>
              <td id="6550007851" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550007852" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007853" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007854" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007855" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550007856" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550007857" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="6550007858" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550007859" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078510" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078511" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500078512" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078513" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078514" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078515" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078516" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078517" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500078518" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078519" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078520" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500078521" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078522" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078523" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078524" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500078525" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500078526" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078527" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078528" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078529" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500078530" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500078531" className="cl1">
                <p className="cl2">-29</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CLINIQUE LILLE SUD</td>
              <td id="6550007861" className="cl1"></td>
              <td id="6550007862" className="cl1"></td>
              <td id="6550007863" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550007864" className="cl1">
                <p className="cl9">3</p>
              </td>
              <td id="6550007865" className="cl1"></td>
              <td id="6550007866" className="cl1"></td>
              <td id="6550007867" className="cl1"></td>
              <td id="6550007868" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550007869" className="cl1"></td>
              <td id="65500078610" className="cl1"></td>
              <td id="65500078611" className="cl1"></td>
              <td id="65500078612" className="cl1"></td>
              <td id="65500078613" className="cl1"></td>
              <td id="65500078614" className="cl1"></td>
              <td id="65500078615" className="cl1"></td>
              <td id="65500078616" className="cl1"></td>
              <td id="65500078617" className="cl1"></td>
              <td id="65500078618" className="cl1"></td>
              <td id="65500078619" className="cl1"></td>
              <td id="65500078620" className="cl1"></td>
              <td id="65500078621" className="cl1"></td>
              <td id="65500078622" className="cl1"></td>
              <td id="65500078623" className="cl1"></td>
              <td id="65500078624" className="cl1"></td>
              <td id="65500078625" className="cl1"></td>
              <td id="65500078626" className="cl1"></td>
              <td id="65500078627" className="cl1"></td>
              <td id="65500078628" className="cl1"></td>
              <td id="65500078629" className="cl1"></td>
              <td id="65500078630" className="cl1"></td>
              <td id="65500078631" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">CLINIQUE LES PEUPLIERS</td>
              <td id="6550007991" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007992" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007993" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550007994" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007995" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550007996" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007997" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007998" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550007999" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079911" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079912" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079913" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079914" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079915" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500079916" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079917" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079918" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079919" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079920" className="cl1">
                <p className="cl2">-14</p>
              </td>
              <td id="65500079921" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079922" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079923" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079924" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079927" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079928" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500079929" className="cl1">
                <p className="cl2">-13</p>
              </td>
              <td id="65500079930" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500079931" className="cl1">
                <p className="cl2">-29</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CH INTERCOMMUNAL</td>
              <td id="6550008101" className="cl1"></td>
              <td id="6550008102" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008103" className="cl1"></td>
              <td id="6550008104" className="cl1"></td>
              <td id="6550008105" className="cl1"></td>
              <td id="6550008106" className="cl1"></td>
              <td id="6550008107" className="cl1"></td>
              <td id="6550008108" className="cl1"></td>
              <td id="6550008109" className="cl1"></td>
              <td id="65500081010" className="cl1"></td>
              <td id="65500081011" className="cl1"></td>
              <td id="65500081012" className="cl1"></td>
              <td id="65500081013" className="cl1"></td>
              <td id="65500081014" className="cl1"></td>
              <td id="65500081015" className="cl1"></td>
              <td id="65500081016" className="cl1"></td>
              <td id="65500081017" className="cl1"></td>
              <td id="65500081018" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500081019" className="cl1"></td>
              <td id="65500081020" className="cl1"></td>
              <td id="65500081021" className="cl1"></td>
              <td id="65500081022" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500081023" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500081024" className="cl1"></td>
              <td id="65500081025" className="cl1"></td>
              <td id="65500081026" className="cl1"></td>
              <td id="65500081027" className="cl1"></td>
              <td id="65500081028" className="cl1"></td>
              <td id="65500081029" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500081030" className="cl1">
                <p className="cl2">-27</p>
              </td>
              <td id="65500081031" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">MAIS DE RETRAITE LE CLOS FLEUR</td>
              <td id="6550008361" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008362" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008363" className="cl1"></td>
              <td id="6550008364" className="cl1"></td>
              <td id="6550008365" className="cl1"></td>
              <td id="6550008366" className="cl1"></td>
              <td id="6550008367" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008368" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008369" className="cl1"></td>
              <td id="65500083610" className="cl1"></td>
              <td id="65500083611" className="cl1"></td>
              <td id="65500083612" className="cl1"></td>
              <td id="65500083613" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083614" className="cl1"></td>
              <td id="65500083615" className="cl1"></td>
              <td id="65500083616" className="cl1"></td>
              <td id="65500083617" className="cl1"></td>
              <td id="65500083618" className="cl1"></td>
              <td id="65500083619" className="cl1"></td>
              <td id="65500083620" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500083621" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500083622" className="cl1"></td>
              <td id="65500083623" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083624" className="cl1"></td>
              <td id="65500083625" className="cl1"></td>
              <td id="65500083626" className="cl1"></td>
              <td id="65500083627" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500083628" className="cl1"></td>
              <td id="65500083629" className="cl1"></td>
              <td id="65500083630" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083631" className="cl1">
                <p className="cl2">-29</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">M.A.S PAPILLONS BLANCS</td>
              <td id="6550008391" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008392" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008393" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008394" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008395" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008396" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550008397" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008398" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008399" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083911" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083912" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083913" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083914" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083915" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083916" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083917" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083918" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083919" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083920" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500083921" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083922" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083923" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500083924" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083925" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083927" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083928" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500083929" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500083930" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500083931" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CCAS DE ROUBAIX</td>
              <td id="6550008491" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008492" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008493" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008494" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008495" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008496" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008497" className="cl1">
                <p className="cl2">13</p>
              </td>
              <td id="6550008498" className="cl1"></td>
              <td id="6550008499" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084910" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084911" className="cl1"></td>
              <td id="65500084912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084913" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500084914" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084915" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084916" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084917" className="cl1"></td>
              <td id="65500084918" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084919" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084920" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500084921" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084922" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084923" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500084924" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500084925" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084927" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="65500084928" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500084929" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500084930" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500084931" className="cl1">
                <p className="cl2">-29</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CENTRE HELENE BOREL</td>
              <td id="6550008541" className="cl1"></td>
              <td id="6550008542" className="cl1"></td>
              <td id="6550008543" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008544" className="cl1"></td>
              <td id="6550008545" className="cl1"></td>
              <td id="6550008546" className="cl1"></td>
              <td id="6550008547" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008548" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008549" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085410" className="cl1"></td>
              <td id="65500085411" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085412" className="cl1"></td>
              <td id="65500085413" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085414" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085415" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085416" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085417" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085418" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085419" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085420" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085421" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500085422" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085423" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085424" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500085425" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085426" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085427" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085428" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085429" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085430" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085431" className="cl1">
                <p className="cl2">-28</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">M.A.S DE DENAIN</td>
              <td id="6550008591" className="cl1">
                <p className="cl2">30</p>
              </td>
              <td id="6550008592" className="cl1"></td>
              <td id="6550008593" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008594" className="cl1"></td>
              <td id="6550008595" className="cl1"></td>
              <td id="6550008596" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="6550008597" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008598" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008599" className="cl1"></td>
              <td id="65500085910" className="cl1"></td>
              <td id="65500085911" className="cl1"></td>
              <td id="65500085912" className="cl1"></td>
              <td id="65500085913" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500085914" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085915" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085916" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500085917" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085918" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500085919" className="cl1"></td>
              <td id="65500085920" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085921" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085922" className="cl1"></td>
              <td id="65500085923" className="cl1"></td>
              <td id="65500085924" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500085925" className="cl1"></td>
              <td id="65500085926" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500085927" className="cl1"></td>
              <td id="65500085928" className="cl1">
                <p className="cl2">-26</p>
              </td>
              <td id="65500085929" className="cl1">
                <p className="cl2">-27</p>
              </td>
              <td id="65500085930" className="cl1"></td>
              <td id="65500085931" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">RESIDENCE GEORGES DELFOSSE</td>
              <td id="6550008681" className="cl1"></td>
              <td id="6550008682" className="cl1"></td>
              <td id="6550008683" className="cl1"></td>
              <td id="6550008684" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550008685" className="cl1"></td>
              <td id="6550008686" className="cl1"></td>
              <td id="6550008687" className="cl1"></td>
              <td id="6550008688" className="cl1"></td>
              <td id="6550008689" className="cl1"></td>
              <td id="65500086810" className="cl1"></td>
              <td id="65500086811" className="cl1"></td>
              <td id="65500086812" className="cl1"></td>
              <td id="65500086813" className="cl1"></td>
              <td id="65500086814" className="cl1"></td>
              <td id="65500086815" className="cl1"></td>
              <td id="65500086816" className="cl1"></td>
              <td id="65500086817" className="cl1"></td>
              <td id="65500086818" className="cl1"></td>
              <td id="65500086819" className="cl1"></td>
              <td id="65500086820" className="cl1"></td>
              <td id="65500086821" className="cl1"></td>
              <td id="65500086822" className="cl1"></td>
              <td id="65500086823" className="cl1"></td>
              <td id="65500086824" className="cl1"></td>
              <td id="65500086825" className="cl1"></td>
              <td id="65500086826" className="cl1"></td>
              <td id="65500086827" className="cl1"></td>
              <td id="65500086828" className="cl1"></td>
              <td id="65500086829" className="cl1"></td>
              <td id="65500086830" className="cl1"></td>
              <td id="65500086831" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">I.M.P.R.O LES PAPILLONS BLANCS</td>
              <td id="6550008801" className="cl1">
                <p className="cl2">24</p>
              </td>
              <td id="6550008802" className="cl1">
                <p className="cl2">23</p>
              </td>
              <td id="6550008803" className="cl1">
                <p className="cl2">24</p>
              </td>
              <td id="6550008804" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550008805" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008806" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="6550008807" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008808" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008809" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088010" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088011" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500088012" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088013" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088014" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088015" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088016" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088017" className="cl1">
                <p className="cl2">12</p>
              </td>
              <td id="65500088018" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500088019" className="cl1"></td>
              <td id="65500088020" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088021" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088022" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088023" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088024" className="cl1"></td>
              <td id="65500088025" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088026" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088027" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500088028" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500088029" className="cl1">
                <p className="cl2">-27</p>
              </td>
              <td id="65500088030" className="cl1">
                <p className="cl2">-28</p>
              </td>
              <td id="65500088031" className="cl1">
                <p className="cl2">-26</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">S.I.A.D DE WATTRELOS</td>
              <td id="6550008811" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008812" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008813" className="cl1">
                <p className="cl2">28</p>
              </td>
              <td id="6550008814" className="cl1"></td>
              <td id="6550008815" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008816" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008817" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550008818" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550008819" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088110" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500088111" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088112" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088113" className="cl1"></td>
              <td id="65500088114" className="cl1"></td>
              <td id="65500088115" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088116" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088117" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088118" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088119" className="cl1"></td>
              <td id="65500088120" className="cl1"></td>
              <td id="65500088121" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088122" className="cl1"></td>
              <td id="65500088123" className="cl1"></td>
              <td id="65500088124" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500088125" className="cl1"></td>
              <td id="65500088126" className="cl1"></td>
              <td id="65500088127" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088128" className="cl1">
                <p className="cl2">-21</p>
              </td>
              <td id="65500088129" className="cl1">
                <p className="cl2">-25</p>
              </td>
              <td id="65500088130" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500088131" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">EHPAD STE CAMILLE</td>
              <td id="6550008901" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008902" className="cl1"></td>
              <td id="6550008903" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008904" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008905" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550008906" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550008907" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008908" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008909" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089010" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089011" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500089012" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089013" className="cl1">
                <p className="cl2">17</p>
              </td>
              <td id="65500089014" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089015" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089016" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089017" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089018" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089019" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089020" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089021" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089022" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089023" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="65500089024" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089025" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089026" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089027" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089028" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089029" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089030" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089031" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">S.S.I.A.D DE FACHES THUMESNIL</td>
              <td id="6550008991" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550008992" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550008993" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008994" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008995" className="cl1"></td>
              <td id="6550008996" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="6550008997" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550008998" className="cl1"></td>
              <td id="6550008999" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500089910" className="cl1"></td>
              <td id="65500089911" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089912" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089913" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500089914" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500089915" className="cl1"></td>
              <td id="65500089916" className="cl1"></td>
              <td id="65500089917" className="cl1"></td>
              <td id="65500089918" className="cl1"></td>
              <td id="65500089919" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500089920" className="cl1"></td>
              <td id="65500089921" className="cl1"></td>
              <td id="65500089922" className="cl1"></td>
              <td id="65500089923" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500089924" className="cl1"></td>
              <td id="65500089925" className="cl1"></td>
              <td id="65500089926" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089927" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500089928" className="cl1"></td>
              <td id="65500089929" className="cl1"></td>
              <td id="65500089930" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500089931" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">ABEJ SOLIDARITE</td>
              <td id="6550009731" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550009732" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009733" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550009734" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009735" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550009736" className="cl1">
                <p className="cl9">5</p>
              </td>
              <td id="6550009737" className="cl1">
                <p className="cl9">15</p>
              </td>
              <td id="6550009738" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="6550009739" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097310" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097311" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097312" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097313" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097314" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097315" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097316" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097317" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500097318" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097319" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097320" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097321" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097322" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097323" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097324" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097325" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097326" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097327" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097328" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097329" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500097330" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500097331" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">GROUPEMENT DE MOYENS EN IMAGER</td>
              <td id="6550010111" className="cl1"></td>
              <td id="6550010112" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="6550010113" className="cl1"></td>
              <td id="6550010114" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550010115" className="cl1"></td>
              <td id="6550010116" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010117" className="cl1"></td>
              <td id="6550010118" className="cl1">
                <p className="cl2">0</p>
              </td>
              <td id="6550010119" className="cl1"></td>
              <td id="65500101110" className="cl1"></td>
              <td id="65500101111" className="cl1"></td>
              <td id="65500101112" className="cl1"></td>
              <td id="65500101113" className="cl1"></td>
              <td id="65500101114" className="cl1"></td>
              <td id="65500101115" className="cl1"></td>
              <td id="65500101116" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500101117" className="cl1"></td>
              <td id="65500101118" className="cl1"></td>
              <td id="65500101119" className="cl1">
                <p className="cl2">-17</p>
              </td>
              <td id="65500101120" className="cl1"></td>
              <td id="65500101121" className="cl1"></td>
              <td id="65500101122" className="cl1"></td>
              <td id="65500101123" className="cl1"></td>
              <td id="65500101124" className="cl1"></td>
              <td id="65500101125" className="cl1"></td>
              <td id="65500101126" className="cl1"></td>
              <td id="65500101127" className="cl1"></td>
              <td id="65500101128" className="cl1"></td>
              <td id="65500101129" className="cl1"></td>
              <td id="65500101130" className="cl1"></td>
              <td id="65500101131" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">HOPITAL ST PHILIBERT</td>
              <td id="6550010231" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550010232" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010233" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550010234" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010235" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010236" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010237" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550010238" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010239" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102310" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102311" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102312" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102313" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102314" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102315" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102316" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102317" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102318" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102319" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102320" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102321" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102322" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102323" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102324" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102325" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102326" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102327" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102328" className="cl1">
                <p className="cl2">-26</p>
              </td>
              <td id="65500102329" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102330" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102331" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">HOPITAL ST VINCENT</td>
              <td id="6550010241" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010242" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550010243" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010244" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550010245" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550010246" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550010247" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550010248" className="cl1">
                <p className="cl2">22</p>
              </td>
              <td id="6550010249" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102410" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102411" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102412" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102413" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102414" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102415" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102416" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102417" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="65500102418" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102419" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102420" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102421" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102422" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102423" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102424" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102425" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102426" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102427" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102428" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102429" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500102430" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500102431" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">M.A.S DE TOURCOING</td>
              <td id="6550013651" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550013652" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550013653" className="cl1"></td>
              <td id="6550013654" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550013655" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550013656" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550013657" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550013658" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550013659" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136510" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136511" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136512" className="cl1">
                <p className="cl2">15</p>
              </td>
              <td id="65500136513" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136514" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500136515" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136516" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136517" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500136518" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136519" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136520" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="65500136521" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136522" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500136523" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136524" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136525" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136526" className="cl1"></td>
              <td id="65500136527" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136528" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500136529" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136530" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500136531" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">FAM DE QUENEHEM</td>
              <td id="6550014011" className="cl1"></td>
              <td id="6550014012" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="6550014013" className="cl1"></td>
              <td id="6550014014" className="cl1"></td>
              <td id="6550014015" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550014016" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550014017" className="cl1"></td>
              <td id="6550014018" className="cl1"></td>
              <td id="6550014019" className="cl1"></td>
              <td id="65500140110" className="cl1"></td>
              <td id="65500140111" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="65500140112" className="cl1"></td>
              <td id="65500140113" className="cl1"></td>
              <td id="65500140114" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500140115" className="cl1"></td>
              <td id="65500140116" className="cl1"></td>
              <td id="65500140117" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500140118" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500140119" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500140120" className="cl1"></td>
              <td id="65500140121" className="cl1"></td>
              <td id="65500140122" className="cl1"></td>
              <td id="65500140123" className="cl1"></td>
              <td id="65500140124" className="cl1"></td>
              <td id="65500140125" className="cl1"></td>
              <td id="65500140126" className="cl1"></td>
              <td id="65500140127" className="cl1"></td>
              <td id="65500140128" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500140129" className="cl1"></td>
              <td id="65500140130" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500140131" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">EHPAD LA RITOURNELLE</td>
              <td id="6550015081" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550015082" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550015083" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550015084" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550015085" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550015086" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550015087" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550015088" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550015089" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150810" className="cl1">
                <p className="cl2">22</p>
              </td>
              <td id="65500150811" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150812" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150813" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150814" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150815" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150816" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150817" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150818" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150819" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150820" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150821" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150822" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150823" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150824" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150825" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150826" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150827" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150828" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150829" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500150830" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500150831" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">RESIDENCE PORTE DE GAND</td>
              <td id="6550016021" className="cl1"></td>
              <td id="6550016022" className="cl1"></td>
              <td id="6550016023" className="cl1">
                <p className="cl2">13</p>
              </td>
              <td id="6550016024" className="cl1"></td>
              <td id="6550016025" className="cl1"></td>
              <td id="6550016026" className="cl1"></td>
              <td id="6550016027" className="cl1"></td>
              <td id="6550016028" className="cl1"></td>
              <td id="6550016029" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500160210" className="cl1"></td>
              <td id="65500160211" className="cl1"></td>
              <td id="65500160212" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500160213" className="cl1"></td>
              <td id="65500160214" className="cl1"></td>
              <td id="65500160215" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500160216" className="cl1"></td>
              <td id="65500160217" className="cl1"></td>
              <td id="65500160218" className="cl1"></td>
              <td id="65500160219" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500160220" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500160221" className="cl1"></td>
              <td id="65500160222" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500160223" className="cl1"></td>
              <td id="65500160224" className="cl1"></td>
              <td id="65500160225" className="cl1"></td>
              <td id="65500160226" className="cl1"></td>
              <td id="65500160227" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500160228" className="cl1"></td>
              <td id="65500160229" className="cl1"></td>
              <td id="65500160230" className="cl1"></td>
              <td id="65500160231" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">MAISON DE RET LES BUISSONNETS</td>
              <td id="6550016031" className="cl1"></td>
              <td id="6550016032" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550016033" className="cl1"></td>
              <td id="6550016034" className="cl1"></td>
              <td id="6550016035" className="cl1"></td>
              <td id="6550016036" className="cl1"></td>
              <td id="6550016037" className="cl1"></td>
              <td id="6550016038" className="cl1"></td>
              <td id="6550016039" className="cl1"></td>
              <td id="65500160310" className="cl1"></td>
              <td id="65500160311" className="cl1"></td>
              <td id="65500160312" className="cl1"></td>
              <td id="65500160313" className="cl1"></td>
              <td id="65500160314" className="cl1"></td>
              <td id="65500160315" className="cl1"></td>
              <td id="65500160316" className="cl1"></td>
              <td id="65500160317" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500160318" className="cl1"></td>
              <td id="65500160319" className="cl1"></td>
              <td id="65500160320" className="cl1"></td>
              <td id="65500160321" className="cl1"></td>
              <td id="65500160322" className="cl1"></td>
              <td id="65500160323" className="cl1"></td>
              <td id="65500160324" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500160325" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500160326" className="cl1"></td>
              <td id="65500160327" className="cl1"></td>
              <td id="65500160328" className="cl1"></td>
              <td id="65500160329" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500160330" className="cl1"></td>
              <td id="65500160331" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">M.A.S DU NOUVEAU MONDE</td>
              <td id="6550016881" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550016882" className="cl1">
                <p className="cl2">18</p>
              </td>
              <td id="6550016883" className="cl1"></td>
              <td id="6550016884" className="cl1"></td>
              <td id="6550016885" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550016886" className="cl1">
                <p className="cl9">2</p>
              </td>
              <td id="6550016887" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550016888" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550016889" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500168810" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500168811" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500168812" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168813" className="cl1">
                <p className="cl2">15</p>
              </td>
              <td id="65500168814" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168815" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168816" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168817" className="cl1"></td>
              <td id="65500168818" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168819" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500168820" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500168821" className="cl1"></td>
              <td id="65500168822" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500168823" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168824" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500168825" className="cl1"></td>
              <td id="65500168826" className="cl1"></td>
              <td id="65500168827" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500168828" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500168829" className="cl1">
                <p className="cl2">-27</p>
              </td>
              <td id="65500168830" className="cl1">
                <p className="cl2">-24</p>
              </td>
              <td id="65500168831" className="cl1"></td>
            </tr>
            <tr>
              <td className="cl8">GROUPE BIOPATH - SIEGE</td>
              <td id="6550019081" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019082" className="cl1">
                <p className="cl2">11</p>
              </td>
              <td id="6550019083" className="cl1">
                <p className="cl2">12</p>
              </td>
              <td id="6550019084" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="6550019085" className="cl1">
                <p className="cl2">4</p>
              </td>
              <td id="6550019086" className="cl1">
                <p className="cl2">12</p>
              </td>
              <td id="6550019087" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019088" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="6550019089" className="cl1">
                <p className="cl2">7</p>
              </td>
              <td id="65500190810" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="65500190811" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500190812" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190813" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190814" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190815" className="cl1">
                <p className="cl2">10</p>
              </td>
              <td id="65500190816" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190817" className="cl1">
                <p className="cl2">14</p>
              </td>
              <td id="65500190818" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190819" className="cl1">
                <p className="cl2">8</p>
              </td>
              <td id="65500190820" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190821" className="cl1">
                <p className="cl2">5</p>
              </td>
              <td id="65500190822" className="cl1">
                <p className="cl2">-18</p>
              </td>
              <td
                id="65500190823"
                /* The above code is setting the style of an HTML element with the following
                    properties:
                    - border-right: 1px solid rgb(204, 204, 204)
                    - width: 35px
                    - max-width: 35px
                    - padding: 0px
                    - margin: 0px
                    - text-align: center */
                className="cl1"
              >
                <p className="cl2">2</p>
              </td>
              <td id="65500190824" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190825" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190826" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190827" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190828" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190829" className="cl1">
                <p className="cl2">1</p>
              </td>
              <td id="65500190830" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500190831" className="cl1">
                <p className="cl2">1</p>
              </td>
            </tr>
            <tr>
              <td className="cl8">CLINIQUE LILLE SUD</td>
              <td id="6550019321" className="cl1"></td>
              <td id="6550019322" className="cl1"></td>
              <td id="6550019323" className="cl1">
                <p className="cl2">6</p>
              </td>
              <td id="6550019324" className="cl1">
                <p className="cl2">3</p>
              </td>
              <td id="6550019325" className="cl1"></td>
              <td id="6550019326" className="cl1"></td>
              <td id="6550019327" className="cl1"></td>
              <td id="6550019328" className="cl1">
                <p className="cl9">1</p>
              </td>
              <td id="6550019329" className="cl1"></td>
              <td id="65500193210" className="cl1"></td>
              <td id="65500193211" className="cl1"></td>
              <td id="65500193212" className="cl1"></td>
              <td id="65500193213" className="cl1">
                <p className="cl2">2</p>
              </td>
              <td id="65500193214" className="cl1"></td>
              <td id="65500193215" className="cl1"></td>
              <td id="65500193216" className="cl1"></td>
              <td id="65500193217" className="cl1"></td>
              <td id="65500193218" className="cl1"></td>
              <td id="65500193219" className="cl1"></td>
              <td id="65500193220" className="cl1"></td>
              <td id="65500193221" className="cl1"></td>
              <td id="65500193222" className="cl1"></td>
              <td id="65500193223" className="cl1"></td>
              <td id="65500193224" className="cl1"></td>
              <td id="65500193225" className="cl1"></td>
              <td id="65500193226" className="cl1"></td>
              <td id="65500193227" className="cl1"></td>
              <td id="65500193228" className="cl1"></td>
              <td id="65500193229" className="cl1"></td>
              <td id="65500193230" className="cl1"></td>
              <td id="65500193231" className="cl1"></td>
            </tr>
          </tbody>
        </table>
        {/* </div> */}
      </div>
    </div>
  );
}
